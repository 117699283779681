import React from 'react';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Portal from '@material-ui/core/Portal';

interface Props {
  loading: boolean;
  hasBackdrop?: boolean;
  z?: boolean;
  transparency?: boolean;
  customClass?: string;
}

const CrmLoader = ({ loading, hasBackdrop = true, z = true, transparency = false, customClass = '' }: Props) => {
  const backdropColorClass = transparency ? 'backdrop-transparent' : 'backdrop-color';

  return (
    <>
      {loading &&
        (hasBackdrop ? (
          <Portal>
            <Backdrop
              open={loading}
              classes={{ root: z ? 'more-z ' + backdropColorClass + ' ' + customClass : backdropColorClass + ' ' + customClass }}
            >
              <CircularProgress color='primary' />
            </Backdrop>
          </Portal>
        ) : (
          <div
            className={
              z
                ? 'more-z class-wrapper absolute-position ' + backdropColorClass + ' ' + customClass
                : ' class-wrapper absolute-position ' + backdropColorClass + ' ' + customClass
            }
          >
            <CircularProgress color='primary' className='absolute-position-relative-content-loader' />
          </div>
        ))}
    </>
  );
};

export default CrmLoader;
