import React, { memo, useEffect, useState } from 'react';

import { useTranslation } from '@tecma/i18n';

import { useModuleAdditionalInfo } from '@v2/@module-clients/hooks/useModuleAdditionalInfo';

import { PageWrapper } from './Wrapper';
import { DrawerFilters } from '../DrawerFilters/DrawerFilters.component';
import { Table } from '../Table/Table.Component';
import { Toolbar } from '../Toolbar/Toolbar.Component';

import './theme.scss';

interface Props {
  project_id: string;
  showToolbar: boolean;
}

export const PageClients = memo(({ project_id, showToolbar }: Props) => {
  const { t } = useTranslation();
  const [isDrawerFiltersOpen, setIsDrawerFiltersOpen] = useState(false);
  const { setSelectedFilters, activeFilters, setActiveFilters } = useModuleAdditionalInfo(project_id);

  if (sessionStorage.getItem('projectId')) {
    if (sessionStorage.getItem('projectId') !== project_id) {
      sessionStorage.removeItem('activeFilters');
      sessionStorage.removeItem('searchText');
      sessionStorage.removeItem('tableSelectedTab');
      sessionStorage.setItem('projectId', project_id);
    }
  } else {
    sessionStorage.setItem('projectId', project_id);
  }

  const openDrawerFilters = () => {
    setIsDrawerFiltersOpen(true);
  };
  const closeDrawerFilters = () => {
    setIsDrawerFiltersOpen(false);
    setSelectedFilters();
  };

  useEffect(() => {
    const savedActiveFilters = sessionStorage.getItem('activeFilters');
    if (!activeFilters && savedActiveFilters) {
      setActiveFilters(JSON.parse(savedActiveFilters));
    }
  }, []);

  return (
    <PageWrapper className='clients-page-wrapper'>
      {/* <PageHeader className="clients-page-header">
        <p className="clients-page-title">{t('header.title')}</p>
        <PageHeaderActions className="page-header-actions">
          <button />
        </PageHeaderActions>
      </PageHeader> */}
      <h4 className='clients-page-description'>{t('pageDescription')}</h4>
      {showToolbar && (
        <Toolbar defaultValue={sessionStorage.getItem('searchText') || ''} project_id={project_id} openDrawerFilters={openDrawerFilters} />
      )}
      <Table project_id={project_id} />
      <DrawerFilters open={isDrawerFiltersOpen} onClose={closeDrawerFilters} projectId={project_id} />
    </PageWrapper>
  );
});
