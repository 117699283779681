import { TFunction } from '@tecma/i18n';

import { toCamelCase } from '@v2/helpers/toCamelCase';

import { AdditionalInfo, AdditionalInfoName, SelectedFilters, SelectFilterAppointees, SelectFilterItems } from '../declarations/common';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
  selectorActions,
  selectorActiveFilters,
  selectorAppointees,
  selectorLoadingActions,
  selectorLoadingAppointees,
  selectorLoadingOtherInfo,
  selectorLoadingTags,
  selectorOtherInfo,
  selectorSelectedFilters,
  selectorTags,
} from '../redux/selectors';
import { sliceAdditionalInfo } from '../redux/slice';
import { thunkAllClientsAdditionalInfo, thunkBackOfficeUsers, thunkProjectActions, thunkProjectTags } from '../redux/thunks';

export const useModuleAdditionalInfo = (project_id: string) => {
  const dispatch = useAppDispatch();
  const tags = useAppSelector(selectorTags);
  const actions = useAppSelector(selectorActions);
  const otherInfo = useAppSelector(selectorOtherInfo);
  const appointees = useAppSelector(selectorAppointees);
  const loadingTags = useAppSelector(selectorLoadingTags);
  const loadingActions = useAppSelector(selectorLoadingActions);
  const loadingOtherInfo = useAppSelector(selectorLoadingOtherInfo);
  const loadingAppointees = useAppSelector(selectorLoadingAppointees);
  const selectedFilters = useAppSelector(selectorSelectedFilters);
  const activeFilters = useAppSelector(selectorActiveFilters);
  const elementSectionLimit = 30;

  const getProjectTags = () => {
    dispatch(thunkProjectTags({ project_id }));
  };
  const getProjectActions = () => {
    dispatch(thunkProjectActions({ project_id }));
  };
  const getProjectOtherInfo = () => {
    dispatch(thunkAllClientsAdditionalInfo({ project_id }));
  };
  const getProjectAppointees = () => {
    dispatch(thunkBackOfficeUsers({ project_id }));
  };
  const getAdditionalInfoByName = (name: AdditionalInfoName): AdditionalInfo[] => {
    if (name === 'tags') return tags ?? [];
    if (name === 'actions') return actions ?? [];
    if (name === 'otherInfo') return otherInfo ?? [];
    return [];
  };
  const getLimitedItems = (name: AdditionalInfoName, limit: number) => {
    let totalTags = 0;
    const result: AdditionalInfo[] = [];
    const items = getAdditionalInfoByName(name);
    for (let i = 0; i < items.length; i++) {
      if (totalTags >= limit) break;
      const section = items[i];
      const remainingTags = limit - totalTags;
      const tagsToAdd = section.subSections.slice(0, remainingTags);
      result.push({
        section: section.section,
        subSections: tagsToAdd,
      });
      totalTags += tagsToAdd.length;
    }
    return result;
  };
  const countAllTags = (): number => {
    return tags?.reduce((total, section) => total + section.subSections.length, 0) ?? 0;
  };
  const countAllActions = (): number => {
    return actions?.reduce((total, section) => total + section.subSections.length, 0) ?? 0;
  };
  const countAllOtherInfo = (): number => {
    return otherInfo?.reduce((total, section) => total + section.subSections.length, 0) ?? 0;
  };
  const itemsToRender = (name: AdditionalInfoName, showAllitems: boolean) => {
    if (showAllitems) return getAdditionalInfoByName(name);
    return getLimitedItems(name, elementSectionLimit);
  };
  const itemsExceedLimit = (name: AdditionalInfoName) => {
    switch (name) {
      case 'tags':
        return countAllTags() > elementSectionLimit;
      case 'actions':
        return countAllActions() > elementSectionLimit;
      case 'otherInfo':
        return countAllOtherInfo() > elementSectionLimit;
    }
  };
  const getAllItems = (name: AdditionalInfoName, t: TFunction) => {
    return getAdditionalInfoByName(name).flatMap((section) =>
      section.subSections.map((subSection) =>
        t(`${getSectionsGroupName(name)}.${toCamelCase(section.section)}.${name}.${toCamelCase(subSection)}`),
      ),
    );
  };
  const getItemsBySearchText = (name: AdditionalInfoName, searchText: string, t: TFunction) => {
    const items = getAdditionalInfoByName(name);
    if (!searchText || !items.length) return [];
    const searchItems = getAllItems(name, t).filter((item) => item.toLowerCase().includes(searchText.toLowerCase() || '')) || [];
    const filteredItems = items
      ?.map((item) => ({
        ...item,
        subSections: item.subSections.filter((subSection) =>
          searchItems.includes(t(`${getSectionsGroupName(name)}.${toCamelCase(item.section)}.${name}.${toCamelCase(subSection)}`)),
        ),
      }))
      .filter((item) => item.subSections.length > 0);
    return filteredItems;
  };
  const getAppointeesBySearchText = (searchText: string) => {
    if (!searchText || !appointees?.length) return [];
    const filteredAppointees = appointees.filter(
      (appointee) =>
        appointee.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
        appointee.lastName.toLowerCase().includes(searchText.toLowerCase()),
    );
    return filteredAppointees;
  };
  const onSelectItem = ({ checked, value, section, name }: SelectFilterItems) => {
    const selectedItems = JSON.parse(JSON.stringify(selectedFilters?.[name] || []));
    const sectionIndex = selectedItems.findIndex((item) => item.section === section);
    if (checked) {
      if (sectionIndex >= 0) {
        selectedItems[sectionIndex].subSections.push(value);
      } else {
        selectedItems.push({ section, subSections: [value] });
      }
    } else {
      const subSections = selectedItems[sectionIndex].subSections.filter((subSection) => subSection !== value);
      if (subSections.length === 0) {
        selectedItems.splice(sectionIndex, 1);
      } else {
        selectedItems[sectionIndex].subSections = subSections;
      }
    }
    if (selectedFilters && selectedFilters[name] && !selectedItems?.length) {
      const updatedFilters = JSON.parse(JSON.stringify(selectedFilters));
      delete updatedFilters[name];
      if (Object.keys(updatedFilters).length === 0) {
        dispatch(sliceAdditionalInfo.actions.setSelectedFilters(undefined));
      } else {
        dispatch(sliceAdditionalInfo.actions.setSelectedFilters(updatedFilters));
      }
    } else {
      dispatch(sliceAdditionalInfo.actions.setSelectedFilters({ ...selectedFilters, [name]: selectedItems }));
    }
  };
  const onSelectAppointee = ({ checked, currentAppointee }: SelectFilterAppointees) => {
    const selectedAppointees = selectedFilters?.appointees ? JSON.parse(JSON.stringify(selectedFilters.appointees)) : [];
    if (checked) {
      selectedAppointees.push(currentAppointee);
    } else {
      const sectionIndex = selectedAppointees.findIndex((appointee) => appointee.id === currentAppointee.id);
      selectedAppointees.splice(sectionIndex, 1);
    }
    if (selectedFilters?.appointees && !selectedAppointees.length) {
      const updatedSelectedFilters = { ...selectedFilters };
      delete updatedSelectedFilters.appointees;

      if (Object.keys(updatedSelectedFilters).length === 0) {
        dispatch(sliceAdditionalInfo.actions.setSelectedFilters(undefined));
      } else {
        dispatch(sliceAdditionalInfo.actions.setSelectedFilters(updatedSelectedFilters));
      }
    } else {
      const updatedSelectedFilters = { ...selectedFilters, appointees: selectedAppointees };
      dispatch(sliceAdditionalInfo.actions.setSelectedFilters(updatedSelectedFilters));
    }
  };
  const setSelectedFilters = (filters?: SelectedFilters) => {
    dispatch(sliceAdditionalInfo.actions.setSelectedFilters(filters));
  };
  const setActiveFilters = (filters?: SelectedFilters) => {
    dispatch(sliceAdditionalInfo.actions.setActiveFilters(filters));
  };
  const sortFilters = (filters: SelectedFilters) => {
    const sortedFilters = JSON.parse(JSON.stringify(filters)) as SelectedFilters;
    Object.keys(sortedFilters).forEach((groupName) => {
      const filterGroup = getAdditionalInfoByName(groupName as AdditionalInfoName);
      const groupSectionIndexes = new Map(filterGroup?.map((item, index) => [item.section, index]));
      const sortedSections = sortedFilters[groupName].sort((a, b) => {
        return groupSectionIndexes.get(a.section)! - groupSectionIndexes.get(b.section)!;
      });
      if (filterGroup?.length)
        sortedSections.forEach((section) => {
          section.subSections.sort((subSectionA, subSectionB) => {
            const currentSection = filterGroup.find((item) => item.section === section.section)!;
            const indexA = currentSection.subSections.indexOf(subSectionA);
            const indexB = currentSection.subSections.indexOf(subSectionB);
            return indexA - indexB;
          });
        });
    });
    return sortedFilters;
  };
  const onSaveActiveFilters = () => {
    const sortedFilters = selectedFilters ? sortFilters(selectedFilters) : selectedFilters;
    dispatch(sliceAdditionalInfo.actions.setActiveFilters(sortedFilters));
    if (!selectedFilters && sessionStorage.getItem('activeFilters')) {
      sessionStorage.removeItem('activeFilters');
    }
    if (selectedFilters) {
      sessionStorage.setItem('activeFilters', JSON.stringify(sortedFilters));
    }
  };
  const getSectionsGroupName = (name: AdditionalInfoName) => {
    switch (name) {
      case 'tags':
        return 'tagSections';
      case 'actions':
        return 'actionSections';
      case 'otherInfo':
        return 'otherInfoSections';
    }
  };
  const mergeSubSections = (section: AdditionalInfoName, t: TFunction) => {
    const sectionName = getSectionsGroupName(section);

    return (
      activeFilters?.[section]?.reduce((acc: string[], filter: AdditionalInfo) => {
        const translatedSubSections = filter.subSections.map((item) => {
          return t(`${sectionName}.${toCamelCase(filter.section)}.${section}.${toCamelCase(item)}`, item);
        });
        return acc.concat(translatedSubSections);
      }, []) ?? []
    );
  };
  const getActiveFiltersToDisplay = (t: TFunction) => {
    const mergedTags = mergeSubSections('tags', t);
    const mergedActions = mergeSubSections('actions', t);
    const mergedOtherInfo = mergeSubSections('otherInfo', t);
    const mergedAppointees =
      activeFilters?.appointees?.reduce((acc: string[], appointee) => {
        return acc.concat(`${appointee.firstName} ${appointee.lastName}`);
      }, []) ?? [];

    return mergedTags.concat(mergedActions, mergedOtherInfo, mergedAppointees);
  };
  const getActiveFiltersSearch = (filters: SelectedFilters) => {
    const tags = filters?.tags?.map((tag) => ({ key: tag.section, value: tag.subSections }));
    const actions = filters?.actions?.map((action) => ({ key: action.section, value: action.subSections }));
    const otherInfo = filters?.otherInfo?.map((otherInfo) => ({ key: otherInfo.section, value: otherInfo.subSections }));
    const appointees = filters?.appointees?.map((appointee) => appointee.id);

    if (!tags && !actions && !otherInfo && !appointees) return {};

    return {
      tag: tags,
      actions,
      otherInfo,
      appointee: appointees,
    };
  };

  return {
    tags,
    actions,
    otherInfo,
    appointees,
    loadingTags,
    loadingActions,
    loadingOtherInfo,
    loadingAppointees,
    selectedFilters,
    activeFilters,
    getProjectTags,
    getProjectActions,
    getProjectOtherInfo,
    getProjectAppointees,
    getLimitedItems,
    countAllTags,
    countAllActions,
    countAllOtherInfo,
    itemsToRender,
    itemsExceedLimit,
    getAllItems,
    getItemsBySearchText,
    getAppointeesBySearchText,
    onSelectItem,
    onSelectAppointee,
    onSaveActiveFilters,
    setSelectedFilters,
    setActiveFilters,
    getActiveFiltersToDisplay,
    getSectionsGroupName,
    getActiveFiltersSearch,
  };
};
