import React, { memo, useEffect, useMemo, useRef, useState } from 'react';

import { Button, SearchBar } from '@tecma/ds';
import { useTranslation } from '@tecma/i18n';
import debounce from 'lodash.debounce';

import { useModuleAdditionalInfo } from '@v2/@module-clients/hooks/useModuleAdditionalInfo';
import { useModuleClients } from '@v2/@module-clients/hooks/useModuleClients';

import './Toolbar.style.scss';

interface Props {
  defaultValue: string;
  project_id: string;
  openDrawerFilters: () => void;
}

export const Toolbar = memo(({ defaultValue = '', project_id, openDrawerFilters }: Props) => {
  const { t } = useTranslation();
  const { onSearch, onCancel, loading, getClients, pageSize } = useModuleClients(project_id);
  const { setSelectedFilters, onSaveActiveFilters, activeFilters, getActiveFiltersToDisplay } = useModuleAdditionalInfo(project_id);
  const [showAllFilters, setShowAllFilters] = useState(false);
  const options = {
    maxLength: {
      value: 400,
      message: t('toolbar.searchBar.error.maxLength', { maxLength: 400 }),
    },
  };

  const activeFiltersToDisplay = getActiveFiltersToDisplay(t);

  const toolbarContentRef = useRef<HTMLDivElement>(null);
  const hiddenButtonFilterRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const activeFilterRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const seeAllButtonRef = useRef<HTMLButtonElement>(null);
  const [visibleFilters, setVisibleFilters] = useState(activeFiltersToDisplay);

  const filtersToShow = useMemo(() => {
    if (showAllFilters || !visibleFilters?.length) return activeFiltersToDisplay;
    return visibleFilters;
  }, [showAllFilters, activeFiltersToDisplay, visibleFilters]);

  const hiddenFiltersLength = activeFiltersToDisplay.length - visibleFilters.length;

  const onResetFilters = () => {
    setSelectedFilters();
    onSaveActiveFilters();
    getClients({ page: 1, perPage: pageSize, searchText: sessionStorage.getItem('searchText') || '' }, {});
  };
  const toggleFiltersVisibility = () => {
    setShowAllFilters((prev) => !prev);
  };

  const updateVisibleFilters = () => {
    if (toolbarContentRef.current) {
      setShowAllFilters(true);
      const containerWidth = toolbarContentRef.current.clientWidth;
      const seeAllButtonWidth = Math.max(seeAllButtonRef.current?.clientWidth ?? 0, 125);
      const availableWidth = containerWidth - seeAllButtonWidth - 12;

      const buttonWidths = hiddenButtonFilterRefs.current.map((button) => button?.clientWidth ?? 0);
      let cumulativeWidth = 0;
      let buttonsToShow = 0;

      for (let i = 0; i < buttonWidths.length; i++) {
        cumulativeWidth += buttonWidths[i];
        if (cumulativeWidth > availableWidth) break;
        if (buttonWidths[i]) buttonsToShow++;
      }

      const newVisibleFilters = activeFiltersToDisplay.slice(0, Math.max(buttonsToShow, 1));

      setVisibleFilters((prevFilters) => {
        if (newVisibleFilters !== prevFilters) {
          return newVisibleFilters;
        }
        return prevFilters;
      });
      setShowAllFilters(false);
    }
  };

  const debouncedUpdateVisibleFilters = useRef(
    debounce(() => {
      updateVisibleFilters();
    }, 100),
  ).current;

  useEffect(() => {
    window.addEventListener('resize', debouncedUpdateVisibleFilters);
    return () => {
      window.removeEventListener('resize', debouncedUpdateVisibleFilters);
    };
  }, []);

  useEffect(() => {
    updateVisibleFilters();
  }, [activeFilters]);

  return (
    <div className='tecma-toolbar'>
      <div className='toolbar-content' ref={toolbarContentRef}>
        <SearchBar
          label={t('toolbar.searchBar.label')}
          placeholder={t('toolbar.searchBar.placeholder')}
          options={options}
          defaultValue={defaultValue}
          onSearch={onSearch}
          onCancel={onCancel}
          isLoading={loading}
          searchButtonText={t('toolbar.searchBar.searchButtonText')}
          cancelButtonText={t('toolbar.searchBar.cancelButtonText')}
        />
        <div className='filter-actions'>
          <Button onClick={openDrawerFilters} data-testid='open-filters-drawer-button' iconName='filter'>
            {t('toolbar.filterActions.openFilters')}
          </Button>
          <Button onClick={onResetFilters} data-testid='clear-active-filters-button' disabled={!activeFilters} outlined iconName='reply'>
            {t('toolbar.filterActions.resetFilters')}
          </Button>
        </div>
      </div>
      {!!filtersToShow.length && (
        <div className='filters-container'>
          <div className='active-filters-list hidden'>
            {activeFiltersToDisplay.map((filter, index) => (
              <Button
                key={filter}
                id={filter}
                className='active-filter'
                onClick={openDrawerFilters}
                size='small'
                outlined
                ref={(el) => (hiddenButtonFilterRefs.current[index] = el)}
              >
                {filter}
              </Button>
            ))}
          </div>
          <div className='active-filters-list'>
            {filtersToShow.map((filter, index) => (
              <Button
                key={filter}
                id={filter}
                className='active-filter'
                onClick={openDrawerFilters}
                size='small'
                outlined
                ref={(el) => (activeFilterRefs.current[index] = el)}
              >
                <span>{filter}</span>
              </Button>
            ))}
            {activeFiltersToDisplay.length > 1 && !!hiddenFiltersLength && (
              <Button className='toggle-filters-view' onClick={toggleFiltersVisibility} ref={seeAllButtonRef} size='small' link>
                {showAllFilters ? t('toolbar.filterActions.seeLess') : t('toolbar.filterActions.seeAll')}
                {!showAllFilters && hiddenFiltersLength > 0 && ` (${hiddenFiltersLength})`}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
});
