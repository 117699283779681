import i18n, { initI18n, loadResources } from '@tecma/i18n';

import { TLocale } from './i18n.declarations';
import { getConfig } from '../config';

interface Params {
  projectId: string;
  languages: Array<TLocale>;
  translations: Record<string, unknown>;
  moduleName: string;
  projectName?: string;
}

const loadTranslation = async (productName: string, language: TLocale) => {
  const response = await fetch(`${getConfig().BUCKET_BASEURL}/${encodeURI(productName)}/i18n/${language}/translation.json`);
  const translations = await response.json();
  i18n.addResourceBundle(language, 'translation', translations, true, true);
};

const loadCustomTranslation = async (productName: string, language: TLocale, projectName: string) => {
  const response = await fetch(
    `${getConfig().BUCKET_BASEURL}/initiatives/${encodeURI(projectName)}/${encodeURI(productName)}/i18n-test/${language}/translation.json`,
  );
  const translations = await response.json();
  i18n.addResourceBundle(language, 'translation', translations, true, true);
};

const loadTranslationsBundle = async (languages: TLocale[], productName: string, projectName?: string) => {
  const localLanguages = languages
    // First load the current language
    .sort((lng) => (i18n.language !== lng ? 1 : -1));
  for (const language of localLanguages) {
    await loadTranslation(productName, language);
    if (projectName) {
      await loadCustomTranslation(productName, language, projectName);
    }
    // If I'm loading the current language force refresh
    if (i18n.language === language) {
      i18n.changeLanguage(language);
    }
  }
  if (!languages.includes(i18n.language as TLocale)) {
    const formattedLanguage = languages.find((lang) => lang.startsWith(i18n.language));
    i18n.changeLanguage(formattedLanguage);
  }
};

export const loadI18n = async ({ projectId, languages, translations, moduleName, projectName }: Params) => {
  if (!i18n.isInitialized) {
    await initI18n(
      translations,
      {
        bucketBase: getConfig().BUCKET_BASEURL,
        productName: `Modules/${moduleName}`,
        apiBase: getConfig().API_URI,
        debug: false,
      },
      'en-GB',
    );
    await loadResources({
      id: projectId,
      displayName: projectName ?? '',
      languages: languages,
    });
  } else {
    await loadTranslationsBundle(languages, `Modules/${moduleName}`, projectName);
  }
};
