import React from 'react';

import { useTranslation } from '@tecma/i18n';

import { useModuleAdditionalInfo } from '@v2/@module-clients/hooks/useModuleAdditionalInfo';

import { DrawerFiltersSelectedItem } from './DrawerFiltersSelectedItem.component';

import './DrawerFiltersSelectedItems.style.scss';

interface Props {
  projectId: string;
}

export const DrawerFiltersSelectedItems = ({ projectId }: Props) => {
  const { t } = useTranslation();
  const { activeFilters } = useModuleAdditionalInfo(projectId);

  return (
    <div className='drawer-filters-selected-items'>
      <h4 className='filters-selected-items-section-title'>{t('drawerFilters.filtersSection.activeFilters')}</h4>
      {!!activeFilters?.tags?.length && <DrawerFiltersSelectedItem projectId={projectId} name='tags' />}
      {!!activeFilters?.actions?.length && <DrawerFiltersSelectedItem projectId={projectId} name='actions' />}
      {!!activeFilters?.appointees?.length && <DrawerFiltersSelectedItem projectId={projectId} name='appointees' />}
      {!!activeFilters?.otherInfo?.length && <DrawerFiltersSelectedItem projectId={projectId} name='otherInfo' />}
    </div>
  );
};
