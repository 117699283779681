import { configureStore } from '@reduxjs/toolkit';

import { sliceAdditionalInfo, sliceClienti } from './slice';

export const reduxStore = configureStore({
  reducer: {
    app: sliceClienti.reducer,
    additionalInfoApp: sliceAdditionalInfo.reducer,
  },
});
