import type { TFunction } from '@tecma/i18n';

export const tableFields = {
  contacts: {
    user: 'FULL_NAME',
    email: 'EMAIL',
    tel: 'PHONE',
    createdOn: 'CREATED_ON',
    updatedOn: 'UPDATED_ON',
  },
};

export const tableTabs = (t: TFunction) => [
  { label: t('table.tab.contacts.label'), value: 'contacts' },
  { label: t('table.tab.myHome.label'), value: 'myHome' },
  { label: t('table.tab.crm.label'), value: 'crm' },
];
