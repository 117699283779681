import { tableFields } from '../constants/tableFields';
import { ActiveFilters, ParamsOnSearch, TableTab } from '../declarations/common';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
  selectorActiveFiltersSearch,
  selectorClients,
  selectorDrawerTagsUserId,
  selectorFilterPage,
  selectorFilterPerPage,
  selectorFilterTotalDocs,
  selectorLoading,
  selectorSelectedTab,
  selectorSortDirection,
  selectorSortField,
  selectorTags,
} from '../redux/selectors';
import { sliceClienti } from '../redux/slice';
import { thunkClientList, thunkClientListCRM, thunkClientListMyHome, thunkProjectTags, thunkUpdateClientTags } from '../redux/thunks';

import type { OptionSelect } from '@tecma/ds/lib/components/Select/Select';

export const useModuleClients = (project_id: string) => {
  const dispatch = useAppDispatch();
  const clients = useAppSelector(selectorClients);
  const loading = useAppSelector(selectorLoading);
  const perPage = useAppSelector(selectorFilterPerPage);
  const page = useAppSelector(selectorFilterPage);
  const pageSize = useAppSelector(selectorFilterPerPage);
  const totalDocs = useAppSelector(selectorFilterTotalDocs);
  const sortOrder = useAppSelector(selectorSortDirection);
  const sortField = useAppSelector(selectorSortField);
  const selectedTab = useAppSelector(selectorSelectedTab);
  const drawerTagsUserId = useAppSelector(selectorDrawerTagsUserId);
  const activeFiltersSearch = useAppSelector(selectorActiveFiltersSearch);
  const tags = useAppSelector(selectorTags);

  const getClients = async (params: ParamsOnSearch, filters: ActiveFilters, newSelectedTab?: TableTab) => {
    try {
      const tableSelectedTab = newSelectedTab || sessionStorage.getItem('tableSelectedTab') || 'contacts';
      if (!selectedTab) {
        dispatch(sliceClienti.actions.setSelectedTab(tableSelectedTab));
      }
      switch (tableSelectedTab) {
        case 'contacts':
          dispatch(thunkClientList({ project_id, params, filters }));
          break;
        case 'myHome':
          dispatch(thunkClientListMyHome({ project_id, params, filters }));
          break;
        case 'crm':
          dispatch(thunkClientListCRM({ project_id, params, filters }));
          if (!tags?.length) {
            getProjectTags();
          }
          break;
        default:
          dispatch(thunkClientList({ project_id, params, filters }));
          break;
      }
    } catch (error) {
      console.error('Failed to load client list:', error);
    }
  };

  const onChangePage = (newPage: number) => {
    const searchText = sessionStorage.getItem('searchText') || '';
    const params = {
      page: newPage + 1,
      perPage,
      searchText,
      sortOrder,
      sortField,
    };
    dispatch(sliceClienti.actions.setPage(newPage + 1));
    getClients(params, activeFiltersSearch);
  };

  const onChangeRowsPerPage = (newPageSize: number) => {
    const searchText = sessionStorage.getItem('searchText') || '';
    const params = {
      page: 1,
      perPage: newPageSize,
      searchText,
      sortOrder,
      sortField,
    };
    dispatch(sliceClienti.actions.setPage(1));
    dispatch(sliceClienti.actions.setPageSize(newPageSize));
    getClients(params, activeFiltersSearch);
  };

  const onSortChange = (columnField: string, direction?: 'asc' | 'desc') => {
    const sortDirection = direction === 'asc' ? 1 : -1;
    const searchText = sessionStorage.getItem('searchText') || '';
    const params = {
      page,
      perPage,
      searchText,
      sortOrder: direction && sortDirection,
      sortField: direction && tableFields.contacts[columnField],
    };
    dispatch(sliceClienti.actions.setSortField(tableFields.contacts[columnField]));
    dispatch(sliceClienti.actions.setSortDirection(direction ? sortDirection : undefined));
    getClients(params, activeFiltersSearch);
  };

  const onTabChange = (newTab: OptionSelect | OptionSelect[]) => {
    if (!Array.isArray(newTab)) {
      sessionStorage.setItem('tableSelectedTab', newTab.value);
      dispatch(sliceClienti.actions.setSelectedTab(newTab.value as TableTab));
      dispatch(sliceClienti.actions.setPage(1));
      const params = {
        page: 1,
        perPage,
        searchText: sessionStorage.getItem('searchText') || '',
        sortOrder,
        sortField,
      };
      getClients(params, activeFiltersSearch, newTab.value as TableTab);
    }
  };

  const onSearch = async (searchText: string) => {
    sessionStorage.setItem('searchText', searchText || '');
    dispatch(sliceClienti.actions.setSortField(undefined));
    dispatch(sliceClienti.actions.setSortDirection(undefined));
    const params = {
      page: 1,
      perPage,
      searchText: searchText.trim(),
    };
    getClients(params, activeFiltersSearch);
  };

  const onCancel = () => {
    sessionStorage.removeItem('searchText');
    dispatch(sliceClienti.actions.setSortField(undefined));
    dispatch(sliceClienti.actions.setSortDirection(undefined));
    getClients({ page, perPage }, activeFiltersSearch);
  };

  const onRefresh = () => {
    const searchText = sessionStorage.getItem('searchText') || '';
    const params = {
      page: 1,
      perPage,
      searchText,
      sortOrder,
      sortField,
    };
    getClients(params, activeFiltersSearch);
  };

  const setDrawerTagsUserId = (userId?: string) => {
    dispatch(sliceClienti.actions.setDrawerTagsUserId(userId));
  };

  const getClientSelectedTags = () => {
    if (!drawerTagsUserId || !clients?.length) return [];
    const selectedClient = clients.find((client) => client._id === drawerTagsUserId);
    return selectedClient?.additionalInfo?.tag ?? [];
  };

  const getUserFullNameById = (id: string) => {
    if (!clients?.length) return '';
    const user = clients.find((client) => client._id === id);
    return `${user?.firstName} ${user?.lastName}`.trim();
  };

  const getProjectTags = () => {
    dispatch(thunkProjectTags({ project_id }));
  };

  const updateClientTags = async (selectedTags: string[]) => {
    await dispatch(thunkUpdateClientTags({ project_id, id: drawerTagsUserId!, tags: selectedTags }));
    const searchText = sessionStorage.getItem('searchText') || '';
    const params = {
      page,
      perPage,
      searchText,
      sortOrder,
      sortField,
    };
    setDrawerTagsUserId();
    getClients(params, activeFiltersSearch);
  };

  return {
    clients,
    getClients,
    loading,
    onSearch,
    onCancel,
    onRefresh,
    onChangePage,
    onChangeRowsPerPage,
    onSortChange,
    onTabChange,
    page,
    pageSize,
    totalDocs,
    selectedTab,
    drawerTagsUserId,
    setDrawerTagsUserId,
    getClientSelectedTags,
    updateClientTags,
    getUserFullNameById,
    activeFiltersSearch,
    getProjectTags,
  };
};
