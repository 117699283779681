import { useState } from 'react';

import { useHistory } from 'react-router-dom';

export const IS_CLIENTS_V2_ACTIVE = process.env.REACT_APP_FEATURE_FLAG_CLIENTS_V2 === 'true';

export const useModuleClients = (
  version: 'legacy' | 'v2',
): {
  moduleClients: 'legacy' | 'v2';
  showBanner: boolean;
  onClickLegacy: () => void;
  onDismiss: () => void;
  onSwitch: (v: 'legacy' | 'v2') => void;
  isLegacyMode: boolean;
  isClientsV2Active: boolean;
  onLogout: () => void;
} => {
  const history = useHistory();
  const initShowBanner = localStorage.getItem('showBanner') !== 'false';
  const moduleClientsVersion = localStorage.getItem('module-clients');
  const [showBanner, setShowBanner] = useState(initShowBanner);
  const isLegacyMode = moduleClientsVersion === 'legacy';

  const onLogout = () => {
    localStorage.removeItem('module-clients');
    localStorage.removeItem('showBanner');
    sessionStorage.removeItem('searchText');
    sessionStorage.removeItem('tableSelectedTab');
    sessionStorage.removeItem('activeFilters');
  };

  const onClickLegacy = () => {
    localStorage.setItem('module-clients', 'legacy');
    history.replace('/clienti');
  };

  const onDismiss = () => {
    setShowBanner(false);
    localStorage.setItem('showBanner', 'false');
    localStorage.setItem('module-clients', version);
  };

  const onSwitch = (v: 'legacy' | 'v2') => {
    console.log({ v });
    localStorage.setItem('showBanner', 'false');
    localStorage.setItem('module-clients', v);
    const path = v === 'v2' ? '/v2/clienti' : '/clienti';
    history.replace(path);
  };

  return {
    moduleClients: moduleClientsVersion === 'v2' ? 'v2' : 'legacy',
    showBanner,
    onClickLegacy,
    onDismiss,
    onSwitch,
    isLegacyMode,
    isClientsV2Active: IS_CLIENTS_V2_ACTIVE,
    onLogout,
  };
};
