import { createSlice } from '@reduxjs/toolkit';

import {
  thunkAllClientsAdditionalInfo,
  thunkBackOfficeUsers,
  thunkClientList,
  thunkClientListCRM,
  thunkClientListMyHome,
  thunkProjectActions,
  thunkProjectTags,
  thunkUpdateClientTags,
} from './thunks';
import { Client, ClientCRM, ClientMyHome, AdditionalInfo, TableTab, Appointee, SelectedFilters } from '../declarations/common';

export const sliceAdditionalInfo = createSlice({
  initialState: {} as {
    tags?: AdditionalInfo[];
    actions?: AdditionalInfo[];
    otherInfo?: AdditionalInfo[];
    appointees?: Appointee[];
    loadingTags?: boolean;
    loadingActions?: boolean;
    loadingOtherInfo?: boolean;
    loadingAppointees?: boolean;
    selectedFilters?: SelectedFilters;
    activeFilters?: SelectedFilters;
  },
  name: 'additionalInfo',
  reducers: {
    setActiveFilters: (state, action) => {
      state.activeFilters = action.payload;
    },
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Additional info (project tags)
    builder.addCase(thunkProjectTags.pending, (state, action) => {
      state.loadingTags = true;
    });
    builder.addCase(thunkProjectTags.fulfilled, (state, action) => {
      delete state.loadingTags;
      state.tags = action.payload.subOptions;
    });
    builder.addCase(thunkProjectTags.rejected, (state) => {
      delete state.loadingTags;
    });
    // Additional info (project actions)
    builder.addCase(thunkProjectActions.pending, (state, action) => {
      state.loadingActions = true;
    });
    builder.addCase(thunkProjectActions.fulfilled, (state, action) => {
      delete state.loadingActions;
      state.actions = action.payload.subOptions;
    });
    builder.addCase(thunkProjectActions.rejected, (state) => {
      delete state.loadingActions;
    });
    // All clients additional info
    builder.addCase(thunkAllClientsAdditionalInfo.pending, (state, action) => {
      state.loadingOtherInfo = true;
    });
    builder.addCase(thunkAllClientsAdditionalInfo.fulfilled, (state, action) => {
      delete state.loadingOtherInfo;
      state.otherInfo = action.payload.map(({ label, options }) => ({ section: label, subSections: options }));
    });
    builder.addCase(thunkAllClientsAdditionalInfo.rejected, (state) => {
      delete state.loadingOtherInfo;
    });
    // Appointees
    builder.addCase(thunkBackOfficeUsers.pending, (state, action) => {
      state.loadingAppointees = true;
    });
    builder.addCase(thunkBackOfficeUsers.fulfilled, (state, action) => {
      delete state.loadingAppointees;
      const appointees = JSON.parse(JSON.stringify(action.payload));
      state.appointees = appointees.sort((a, b) => `${a.firstName + a.lastName}`.localeCompare(b.firstName + b.lastName));
    });
    builder.addCase(thunkBackOfficeUsers.rejected, (state) => {
      delete state.loadingAppointees;
    });
  },
});

export const sliceClienti = createSlice({
  initialState: {} as {
    clients: Array<Client | ClientMyHome | ClientCRM>;
    loading?: boolean;
    loadingDrawer?: boolean;
    projectId?: string;
    filters?: {
      totalDocs: number;
      totalPages: number;
      page: number;
      perPage: number;
      prevPage: number | null;
      nextPage: number | null;
    };
    sortOrder?: 1 | -1;
    sortField?: 'CREATED_ON' | 'UPDATED_ON' | 'EMAIL' | 'FULL_NAME' | 'FIRST_NAME' | 'LAST_NAME' | 'PHONE';
    selectedTab: TableTab;
    drawerTagsUserId?: string;
  },
  name: 'clients',
  reducers: {
    setPage: (state, action) => {
      if (state.filters) state.filters.page = action.payload;
    },
    setPageSize: (state, action) => {
      if (state.filters) state.filters.perPage = action.payload;
    },
    setSortDirection: (state, action) => {
      state.sortOrder = action.payload;
    },
    setSortField: (state, action) => {
      state.sortField = action.payload;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setDrawerTagsUserId: (state, action) => {
      state.drawerTagsUserId = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Contacts tab
    builder.addCase(thunkClientList.pending, (state, action) => {
      state.projectId = action.meta.arg.project_id;
      state.loading = true;
    });
    builder.addCase(thunkClientList.fulfilled, (state, action) => {
      delete state.loading;
      state.clients = action.payload.data;
      state.filters = action.payload.paginationInfo;
    });
    builder.addCase(thunkClientList.rejected, (state) => {
      delete state.loading;
    });
    // MyHome tab
    builder.addCase(thunkClientListMyHome.pending, (state, action) => {
      state.projectId = action.meta.arg.project_id;
      state.loading = true;
    });
    builder.addCase(thunkClientListMyHome.fulfilled, (state, action) => {
      delete state.loading;
      state.clients = action.payload.data;
      state.filters = action.payload.paginationInfo;
    });
    builder.addCase(thunkClientListMyHome.rejected, (state) => {
      delete state.loading;
    });
    // CRM tab
    builder.addCase(thunkClientListCRM.pending, (state, action) => {
      state.projectId = action.meta.arg.project_id;
      state.loading = true;
    });
    builder.addCase(thunkClientListCRM.fulfilled, (state, action) => {
      delete state.loading;
      state.clients = action.payload.data;
      state.filters = action.payload.paginationInfo;
    });
    builder.addCase(thunkClientListCRM.rejected, (state) => {
      delete state.loading;
    });
    // Update client tags
    builder.addCase(thunkUpdateClientTags.pending, (state, action) => {
      state.loadingDrawer = true;
    });
    builder.addCase(thunkUpdateClientTags.fulfilled, (state, action) => {
      delete state.loadingDrawer;
    });
    builder.addCase(thunkUpdateClientTags.rejected, (state) => {
      delete state.loadingDrawer;
    });
  },
});
