import React from 'react';

import { useTranslation } from '@tecma/i18n';

import { useModuleAdditionalInfo } from '@v2/@module-clients/hooks/useModuleAdditionalInfo';

import { DrawerFiltersItemSection } from '../DrawerFiltersItemSection/DrawerFiltersItemSection.component';

import './DrawerFiltersSearchResults.style.scss';

interface Props {
  projectId: string;
  searchText: string;
}

export const DrawerFiltersSearchResults = ({ projectId, searchText }: Props) => {
  const { t } = useTranslation();
  const { getItemsBySearchText, getAppointeesBySearchText, onSelectItem, onSelectAppointee, selectedFilters } =
    useModuleAdditionalInfo(projectId);
  const searchedTags = getItemsBySearchText('tags', searchText, t);
  const searchedActions = getItemsBySearchText('actions', searchText, t);
  const searchedOtherInfo = getItemsBySearchText('otherInfo', searchText, t);
  const searchedAppointees = getAppointeesBySearchText(searchText);
  const noResults = !searchedTags.length && !searchedActions.length && !searchedOtherInfo.length && !searchedAppointees.length;

  return (
    <div className='drawer-filters-search-results'>
      {noResults && (
        <div className='search-not-found'>
          <h4 className='section-label'>{t('drawerFilters.filtersSection.noResults.title')}</h4>
          <span className='section-text'>{t('drawerFilters.filtersSection.noResults.description')}</span>
        </div>
      )}
      {!!searchedTags.length && (
        <DrawerFiltersItemSection
          items={searchedTags}
          title={t('drawerFilters.filtersSection.tags')}
          name='tags'
          onSelectItem={onSelectItem}
          selectedItems={selectedFilters?.tags}
        />
      )}
      {!!searchedActions.length && (
        <DrawerFiltersItemSection
          items={searchedActions}
          title={t('drawerFilters.filtersSection.actions')}
          name='actions'
          onSelectItem={onSelectItem}
          selectedItems={selectedFilters?.actions}
        />
      )}
      {!!searchedAppointees.length && (
        <DrawerFiltersItemSection
          appointees={searchedAppointees}
          title={t('drawerFilters.filtersSection.appointees')}
          name='appointees'
          onSelectAppointee={onSelectAppointee}
          selectedAppointees={selectedFilters?.appointees}
        />
      )}
      {!!searchedOtherInfo.length && (
        <DrawerFiltersItemSection
          items={searchedOtherInfo}
          title={t('drawerFilters.filtersSection.otherInfo')}
          name='otherInfo'
          onSelectItem={onSelectItem}
          selectedItems={selectedFilters?.otherInfo}
        />
      )}
    </div>
  );
};
