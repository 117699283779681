import React from 'react';

import { CheckboxGroup, Checkbox } from '@tecma/ds';
import { useTranslation } from '@tecma/i18n';

import { toCamelCase } from '@v2/helpers/toCamelCase';

import type {
  AdditionalInfo,
  AdditionalInfoName,
  Appointee,
  SelectFilterAppointees,
  SelectFilterItems,
} from '@v2/@module-clients/declarations/common';

import './DrawerFiltersItemSection.style.scss';

interface Props {
  title?: string;
  name: AdditionalInfoName | 'appointees';
  items?: AdditionalInfo[];
  selectedItems?: AdditionalInfo[];
  onSelectItem?: (props: SelectFilterItems) => void;
  appointees?: Appointee[];
  selectedAppointees?: Appointee[];
  onSelectAppointee?: (props: SelectFilterAppointees) => void;
}

export const DrawerFiltersItemSection = ({
  title,
  name,
  items,
  selectedItems,
  onSelectItem,
  appointees,
  selectedAppointees,
  onSelectAppointee,
}: Props) => {
  const { t } = useTranslation();
  const isChecked = (section: string, tag: string) => {
    return !!selectedItems?.find((item) => item.section === section)?.subSections.includes(tag);
  };

  const getSectionsGroupName = () => {
    switch (name) {
      case 'tags':
        return 'tagSections';
      case 'actions':
        return 'actionSections';
      case 'otherInfo':
        return 'otherInfoSections';
    }
  };

  const sectionsGroup = getSectionsGroupName();

  const getSectionName = (section: string) => {
    return t(`${sectionsGroup}.${toCamelCase(section)}.label`, section);
  };
  const getItemName = (section: string, item: string) => {
    return t(`${sectionsGroup}.${toCamelCase(section)}.${name}.${toCamelCase(item)}`, item);
  };

  return items?.length || appointees?.length ? (
    <section className='filters-item-section' data-testid={`filter-item-section-${name}`}>
      {title && <h4 className='filters-item-section-title'>{title}</h4>}
      <div className='section-list'>
        {items?.length ? (
          items.map((itemSection) => (
            <CheckboxGroup label={getSectionName(itemSection.section)} key={itemSection.section} orientation='horizontal'>
              {itemSection.subSections.map((tag) => (
                <Checkbox
                  label={getItemName(itemSection.section, tag)}
                  size='small'
                  key={tag}
                  checked={isChecked(itemSection.section, tag)}
                  onChange={(_, checked) => {
                    if (onSelectItem) {
                      onSelectItem({ checked, value: tag, section: itemSection.section, name });
                    }
                  }}
                />
              ))}
            </CheckboxGroup>
          ))
        ) : (
          <CheckboxGroup label='' orientation='horizontal'>
            {appointees?.map((appointee) => (
              <Checkbox
                label={`${appointee.firstName} ${appointee.lastName}`}
                checked={!!selectedAppointees?.some((selectedAppointee) => selectedAppointee.id === appointee.id)}
                size='small'
                key={appointee.id}
                onChange={(_, checked) => {
                  if (onSelectAppointee) {
                    onSelectAppointee({ checked, currentAppointee: appointee });
                  }
                }}
              />
            ))}
          </CheckboxGroup>
        )}
      </div>
    </section>
  ) : null;
};
