import { ReactNode } from 'react';

import styled from '@emotion/styled';
import { Button, Icon, Tooltip } from '@tecma/ds';
import { TFunction } from '@tecma/i18n';
import { useHistory } from 'react-router-dom';

import { toCamelCase } from '@v2/helpers/toCamelCase';

import TextWithIcon from 'components/specific/TextWithIcon';

import { clientDetailRoute } from '../../../routes/Routes';
import { useStore } from '../../../store/storeUtils';
import { getDates, utilityFormatDate } from '../../helpers/formatDate';
import { Action, AdditionalInfo, Client, ClientCRM, ClientMyHome, TableTab } from '../declarations/common';

import WarningIcon from 'images/assets/warning.svg';

interface Props {
  client: Client;
}
interface MyHomeProps {
  client: ClientMyHome;
  t?: TFunction;
}
interface CRMProps {
  client: ClientCRM;
  t?: TFunction;
  openDrawerTagHandler?: (userId?: string) => void;
  tags?: AdditionalInfo[];
}
interface Column {
  field: string;
  title: string;
  render?: (data: any) => ReactNode;
  sort?: boolean;
}

const MyHomeVersionContainer = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
}));
const MyHomeVersionDot = styled.div<{ confirmed: boolean }>(({ confirmed }) => ({
  backgroundColor: confirmed ? '#37B7C3' : '#FF4E88',
  height: '1rem',
  width: '1rem',
  borderRadius: '50%',
}));
const MyHomeVersionLabel = styled.span(() => ({
  textTransform: 'capitalize',
}));
const FlexContainer = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
}));
const Text = styled.span(() => ({
  fontSize: '.875rem',
  whiteSpace: 'nowrap',
}));

const ItemUser = ({ client }: Props | MyHomeProps | CRMProps) => {
  const store = useStore();
  const history = useHistory();

  const handleClick = () => {
    store.setClientId(client._id);
    const destination = clientDetailRoute.to.replace(':id', client._id);
    store.setRealLocation(destination);
    history.push(destination);
  };

  return (
    <Button
      onClick={handleClick}
      link
      style={{ textAlign: 'left', height: 'auto', padding: 0 }}
    >{`${client.firstName || ''} ${client.lastName || ''}`}</Button>
  );
};
const ItemEmail = ({ client }: Props) => {
  return (
    <Tooltip disableFocusListener title={client.email} placement='top-start'>
      <span>{client.email}</span>
    </Tooltip>
  );
};
const ItemAppointee = ({ client }: Props | CRMProps) => {
  return (
    <TextWithIcon
      text={client.appointee ? `${client.appointee?.firstName} ${client.appointee?.lastName}` : '-'}
      disabled={client.appointee?.isDisabled}
      showIcon={client.appointee?.isDisabled}
      icon={WarningIcon}
    />
  );
};
const ItemMyHomeVersion = ({ client }: MyHomeProps) => {
  if (!client.myhome_version) return <div>-</div>;

  return (
    <MyHomeVersionContainer>
      <MyHomeVersionDot confirmed={client.confirmed} />
      <MyHomeVersionLabel>{client.myhome_version}</MyHomeVersionLabel>
    </MyHomeVersionContainer>
  );
};
const ItemLastAccess = ({ client, t }: MyHomeProps) => {
  if (!client.lastAccess || !t) return <div>-</div>;

  const { days, hours, minutes } = getDates(client.lastAccess);
  const daysAgoLabel = t('table.tab.myHome.field.daysAgo', { days });
  const hoursAgoLabel = t('table.tab.myHome.field.hoursAgo', { hours: `${hours}:${minutes}` });

  return (
    <FlexContainer>
      <Icon iconName='globe-alt' />
      <span>{days > 0 ? daysAgoLabel : hoursAgoLabel}</span>
    </FlexContainer>
  );
};
const ItemMyHomeOperationStatus = ({ client, t }: MyHomeProps) => {
  if (!client.dateOperationFailed || !t) return <div>-</div>;
  const { days, hours, minutes } = getDates(client.dateOperationFailed);
  const iconName = client.myhomeStatus === 'riserva_ok' || client.myhomeStatus === 'proposta_ok' ? 'dotted-circle' : 'exclamation-circle';
  const daysAgoLabel = t('table.tab.myHome.field.daysAgo', { days });
  const hoursAgoLabel = t('table.tab.myHome.field.hoursAgo', { hours: `${hours}:${minutes}` });
  const dateLabel = days > 0 ? daysAgoLabel : hoursAgoLabel;

  return (
    <FlexContainer>
      <Icon iconName={iconName} />
      <span>
        {client.myhomeStatus ? (
          <>
            {t(`table.tab.myHome.status.${client.myhomeStatus}`)} <br />({dateLabel})
          </>
        ) : (
          dateLabel
        )}
      </span>
    </FlexContainer>
  );
};
const ItemTag = ({ client, t, openDrawerTagHandler, tags }: CRMProps) => {
  if (!client || !t) return <div>-</div>;
  const clientTags = client.additionalInfo?.tag;
  const openDrawer = () => {
    if (openDrawerTagHandler) {
      openDrawerTagHandler(client._id);
    }
  };
  const tagLabel = () => {
    if (!clientTags[0]) return '';
    const section = tags?.find((tagSection) => tagSection?.subSections?.includes(clientTags[0]))?.section || '';
    return t(`tagSections.${toCamelCase(section)}.tags.${toCamelCase(clientTags[0])}`, { defaultValue: clientTags[0] });
  };

  return clientTags?.length ? (
    <FlexContainer>
      <Button onClick={openDrawer} size='small' outlined>
        <Text>{tagLabel()}</Text>
      </Button>
      {clientTags.length > 1 && (
        <Button onClick={openDrawer} size='small' style={{ padding: 0 }} link>
          <Text>... +{clientTags.length - 1}</Text>
        </Button>
      )}
    </FlexContainer>
  ) : (
    <Button onClick={openDrawer} size='small' color='secondary' iconName='plus'>
      <Text>{t('table.tab.crm.field.addTag')}</Text>
    </Button>
  );
};

const getLatestAction = (actions: Action[]) => {
  if (actions.length === 1) return actions[0];
  return actions.reduce((acc, curr) => {
    if (new Date(curr.actionDate) > new Date(acc.actionDate)) {
      return curr;
    }
    return acc;
  });
};
const ItemLastAction = ({ client, t }: CRMProps) => {
  if (!client.actions?.length || !t) return <div>-</div>;

  const latestAction = getLatestAction(client.actions);

  return (
    <FlexContainer>
      {t(`actionSections.${toCamelCase(latestAction.category)}.actions.${toCamelCase(latestAction.actionName)}`, {
        defaultValue: latestAction.actionName,
      })}
    </FlexContainer>
  );
};

export const columnsTableClients = (
  t: TFunction,
  selectedTab: TableTab,
  openDrawerTagHandler: () => void,
  tags?: AdditionalInfo[],
): Column[] => {
  const tabs = {
    contacts: [
      {
        field: 'user',
        title: t('table.tab.contacts.column.user'),
        render: (client: Client) => <ItemUser client={client} />,
      },
      {
        field: 'email',
        title: t('table.tab.contacts.column.email'),
        render: (client: Client) => <ItemEmail client={client} />,
      },
      {
        field: 'tel',
        title: t('table.tab.contacts.column.tel'),
        render: (client: Client) => `${client.tel}`,
        sort: false,
      },
      {
        field: 'createdOn',
        title: t('table.tab.contacts.column.createdOn'),
        render: (client: Client) => <div>{`${utilityFormatDate(client.createdOn)}`}</div>,
      },
      {
        field: 'updatedOn',
        title: t('table.tab.contacts.column.updatedOn'),
        render: (client: Client) => <div>{`${utilityFormatDate(client.updatedOn)}`}</div>,
      },
      {
        field: 'appointee',
        title: t('table.tab.contacts.column.appointee'),
        render: (client: Client) => <ItemAppointee client={client} />,
        sort: false,
      },
    ],
    myHome: [
      {
        field: 'user',
        title: t('table.tab.myHome.column.user'),
        render: (client: ClientMyHome) => <ItemUser client={client} />,
      },
      {
        field: 'myHomeVersion',
        title: t('table.tab.myHome.column.myHome'),
        render: (client: ClientMyHome) => <ItemMyHomeVersion client={client} />,
        sort: false,
      },
      {
        field: 'lastAccess',
        title: t('table.tab.myHome.column.lastAccess'),
        render: (client: ClientMyHome) => <ItemLastAccess client={client} t={t} />,
        sort: false,
      },
      {
        field: 'operationStatus',
        title: t('table.tab.myHome.column.operationStatus'),
        render: (client: ClientMyHome) => <ItemMyHomeOperationStatus client={client} t={t} />,
        sort: false,
      },
    ],
    crm: [
      {
        field: 'user',
        title: t('table.tab.crm.column.user'),
        render: (client: ClientCRM) => <ItemUser client={client} />,
      },
      {
        field: 'updatedOn',
        title: t('table.tab.crm.column.updatedOn'),
        render: (client: ClientCRM) => <div>{`${utilityFormatDate(client.updatedOn)}`}</div>,
      },
      {
        field: 'action',
        title: t('table.tab.crm.column.action'),
        render: (client: ClientCRM) => <ItemLastAction client={client} t={t} />,
        sort: false,
      },
      {
        field: 'appointee',
        title: t('table.tab.crm.column.appointee'),
        render: (client: ClientCRM) => <ItemAppointee client={client} />,
        sort: false,
      },
      {
        field: 'tag',
        title: t('table.tab.crm.column.tag'),
        render: (client: ClientCRM) => <ItemTag client={client} t={t} openDrawerTagHandler={openDrawerTagHandler} tags={tags} />,
        sort: false,
      },
    ],
  };
  return tabs[selectedTab];
};
