import { useEffect } from 'react';

import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useTranslation } from '@tecma/i18n';
import { observer } from 'mobx-react';

import { useProjectID } from 'store/hooks/useProjectID';
import { useStore } from 'store/storeUtils';

import { utilityGetDateWithTimezone } from 'utils/getDateWithTimezone';

import { useAppDispatch } from './@v2/@module-clients/redux/hooks';
import { createClientGraphQL } from './@v2/graphql/createClientGraphQL';

const queryClientsAll = gql`
  query getAllClients($project_id: ID!, $getLeadScore: Boolean = false) {
    getAllClientsFollowUp(project_id: $project_id, getLeadScore: $getLeadScore) {
      id
      firstName
      lastName
      status
      avatar
      nAppuntamenti
      nPreventiviRichiesti
      email
      tel
      city
      birthdate
      trattamento
      marketing
      profilazione
      note
      interested_appartments {
        appartment
        status
      }
      selected_appartments {
        appartment
        status
      }
      createdOn
      updatedOn
      coniuge {
        nome
        cognome
        mail
        indirizzo
        tel
      }
      info {
        source
        motivazione
        tempo_ricerca
        mutuo
        leva
        arredo
        date_lead
        source
        budget
        motivazione
        tempo_ricerca
        stato_civile
        caratteristiche
        mutuo
        leva
        arredo
        alternative
        prima_casa
        family {
          adulti
          bambini
          animali
        }
      }
      myhome_version
      user
      appuntamenti {
        id
        startDate
        typology
        vendor {
          id
        }
      }
      additionalInfo
      reserved
      confirmed
      privacyInformation
      myhomeStatus
      lastAccess
      dateOperationFailed
      actions {
        _id
        actionName
        actionDate
        category
        vendor
        quote
        note
        deleted
        createdOn
      }
      leadScore
      nAccesses
      nReserved
      nProposals
      motivazione
      appointee {
        id
        firstName
        lastName
        email
        role
        isDisabled
      }
    }
  }
`;

export const thunkClientListAll = createAsyncThunk<any, { project_id: string }>('client/list-all', async ({ project_id }, thunkAPI) => {
  try {
    const GraphQL = createClientGraphQL();
    const response = await GraphQL.query({ query: queryClientsAll, variables: { project_id } });
    const { getAllClientsFollowUp } = response.data;
    return thunkAPI.fulfillWithValue(getAllClientsFollowUp);
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to load client list');
  }
});

export const SessionStore = observer(({ children }) => {
  const projectID = useProjectID();
  const { t } = useTranslation();
  const store = useStore();
  const cachedVersion = localStorage.getItem('module-clients');
  const dispatch = useAppDispatch();

  async function loadGlobalData() {
    try {
      const zuluDate = new Date();
      const updatedAfter = utilityGetDateWithTimezone(new Date(zuluDate));
      store.setLoadingClients(true);
      const clientList = await dispatch(thunkClientListAll({ project_id: projectID })).unwrap();
      store.setProjectClients(projectID, clientList, updatedAfter);
      store.setClients(clientList);

      store.setLoadingClients(false);
    } catch (error) {
      store.setLoadingClients(false);
      store.setSnackbarError(true, t('snackbar.errorUploadClient_'));
    }
  }

  useEffect(() => {
    if (cachedVersion === 'legacy') loadGlobalData();
  }, []);

  return <>{children}</>;
});
