import React from 'react';

import { Checkbox, CheckboxGroup } from '@tecma/ds';
import { useTranslation } from '@tecma/i18n';

import { AdditionalInfo, AdditionalInfoName, Appointee } from '@v2/@module-clients/declarations/common';
import { useModuleAdditionalInfo } from '@v2/@module-clients/hooks/useModuleAdditionalInfo';
import { toCamelCase } from '@v2/helpers/toCamelCase';

interface Props {
  projectId: string;
  name: 'appointees' | AdditionalInfoName;
}

export const DrawerFiltersSelectedItem = ({ projectId, name }: Props) => {
  const { t } = useTranslation();

  const { onSelectItem, onSelectAppointee, selectedFilters, activeFilters, getSectionsGroupName } = useModuleAdditionalInfo(projectId);
  const items = activeFilters?.[name];

  const isItemChecked = (section: string, tag: string) => {
    const selectedItems = selectedFilters?.[name] as AdditionalInfo[];
    return !!selectedItems?.find((item) => item.section === section)?.subSections.includes(tag);
  };
  const isAppointeeChecked = (appointee: Appointee) => {
    const selectedAppointees = selectedFilters?.[name] as Appointee[];
    return !!selectedAppointees?.some((selectedAppointee) => selectedAppointee.id === appointee.id);
  };

  const getItemName = (section: string, item: string) => {
    return t(`${getSectionsGroupName(name as AdditionalInfoName)}.${toCamelCase(section)}.${name}.${toCamelCase(item)}`, item);
  };
  const handleSelectItem = (checked: boolean, tag: string, groups: AdditionalInfo) => {
    if (onSelectItem) {
      onSelectItem({ checked, value: tag, section: groups.section, name });
    }
  };
  const handleSelectAppointee = (checked: boolean, currentAppointee: Appointee) => {
    if (onSelectAppointee) {
      onSelectAppointee({ checked, currentAppointee });
    }
  };

  return (
    <section className='filters-item-section' data-testid={`active-filter-item-section-${name}`}>
      <div className='section-list'>
        <CheckboxGroup label={t(`drawerFilters.filtersSection.${name}`)} orientation='horizontal'>
          {name !== 'appointees'
            ? items?.map((groups) =>
                groups.subSections.map((tag) => (
                  <Checkbox
                    label={getItemName(groups.section, tag)}
                    size='small'
                    key={tag}
                    checked={isItemChecked(groups.section, tag)}
                    onChange={(_, checked) => handleSelectItem(checked, tag, groups)}
                  />
                )),
              )
            : items?.map((appointee) => (
                <Checkbox
                  label={`${appointee.firstName} ${appointee.lastName}`}
                  size='small'
                  key={appointee.id}
                  checked={isAppointeeChecked(appointee)}
                  onChange={(_, checked) => handleSelectAppointee(checked, appointee)}
                />
              ))}
        </CheckboxGroup>
      </div>
    </section>
  );
};
