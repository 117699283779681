import { createAsyncThunk } from '@reduxjs/toolkit';

import { createClientGraphQL } from '../../graphql/createClientGraphQL';
import { ActiveFilters, ParamsOnSearch } from '../declarations/common';
import {
  ResponseQueryClientList,
  ResponseQueryClientListMyHome,
  QUERY_CLIENT_LIST,
  QUERY_CLIENT_LIST_MYHOME,
  ResponseQueryClientListCRM,
  QUERY_CLIENT_LIST_CRM,
  QUERY_CLIENT_ADDITIONAL_INFO_BY_NAME,
  ResponseQueryAdditionalInfo,
  ResponseMutationUpdateClientTags,
  MUTATION_UPDATE_CLIENT_TAGS,
  QUERY_ALL_CLIENTS_ADDITIONAL_INFO,
  ResponseQueryAllClientsAdditionalInfo,
  ResponseQueryBackOfficeUsers,
  QUERY_BACK_OFFICE_USERS,
} from '../queries/list';

export const thunkClientList = createAsyncThunk<
  ResponseQueryClientList['getCustomersTabInfo'],
  { project_id: string; params: ParamsOnSearch; filters: ActiveFilters }
>('client/list', async ({ project_id, params, filters }, thunkAPI) => {
  try {
    const GraphQL = createClientGraphQL();
    const response = await GraphQL.query({
      query: QUERY_CLIENT_LIST,
      variables: { project_id, params, ...(Object.keys(filters).length > 0 ? { filters } : null) },
    });
    const { getCustomersTabInfo } = response.data as ResponseQueryClientList;
    return thunkAPI.fulfillWithValue(getCustomersTabInfo);
  } catch (error) {
    console.error(error);
    return thunkAPI.rejectWithValue('Failed to load client list');
  }
});

export const thunkClientListMyHome = createAsyncThunk<
  ResponseQueryClientListMyHome['getMyHomeTabInfo'],
  { project_id: string; params: ParamsOnSearch; filters: ActiveFilters }
>('clientMyHome/list', async ({ project_id, params, filters }, thunkAPI) => {
  try {
    const GraphQL = createClientGraphQL();
    const response = await GraphQL.query({
      query: QUERY_CLIENT_LIST_MYHOME,
      variables: { project_id, params, ...(Object.keys(filters).length > 0 ? { filters } : null) },
    });
    const { getMyHomeTabInfo } = response.data as ResponseQueryClientListMyHome;
    return thunkAPI.fulfillWithValue(getMyHomeTabInfo);
  } catch (error) {
    console.error(error);
    return thunkAPI.rejectWithValue('Failed to load MyHome client list');
  }
});

export const thunkClientListCRM = createAsyncThunk<
  ResponseQueryClientListCRM['getCRMTabInfo'],
  { project_id: string; params: ParamsOnSearch; filters: ActiveFilters }
>('clientCRM/list', async ({ project_id, params, filters }, thunkAPI) => {
  try {
    const GraphQL = createClientGraphQL();
    const response = await GraphQL.query({
      query: QUERY_CLIENT_LIST_CRM,
      variables: { project_id, params, ...(Object.keys(filters).length > 0 ? { filters } : null) },
    });
    const { getCRMTabInfo } = response.data as ResponseQueryClientListCRM;
    return thunkAPI.fulfillWithValue(getCRMTabInfo);
  } catch (error) {
    console.error(error);
    return thunkAPI.rejectWithValue('Failed to load CRM client list');
  }
});

export const thunkProjectTags = createAsyncThunk<ResponseQueryAdditionalInfo['getAdditionalInfoByName'], { project_id: string }>(
  'tags/list',
  async ({ project_id }, thunkAPI) => {
    try {
      const GraphQL = createClientGraphQL();
      const response = await GraphQL.query({ query: QUERY_CLIENT_ADDITIONAL_INFO_BY_NAME, variables: { project_id, name: 'tag2' } });
      const { getAdditionalInfoByName } = response.data as ResponseQueryAdditionalInfo;
      return thunkAPI.fulfillWithValue(getAdditionalInfoByName);
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue('Failed to load project tags');
    }
  },
);

export const thunkProjectActions = createAsyncThunk<ResponseQueryAdditionalInfo['getAdditionalInfoByName'], { project_id: string }>(
  'actions/list',
  async ({ project_id }, thunkAPI) => {
    try {
      const GraphQL = createClientGraphQL();
      const response = await GraphQL.query({ query: QUERY_CLIENT_ADDITIONAL_INFO_BY_NAME, variables: { project_id, name: 'actions' } });
      const { getAdditionalInfoByName } = response.data as ResponseQueryAdditionalInfo;
      return thunkAPI.fulfillWithValue(getAdditionalInfoByName);
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue('Failed to load project actions');
    }
  },
);

export const thunkAllClientsAdditionalInfo = createAsyncThunk<
  ResponseQueryAllClientsAdditionalInfo['getAllAdditionalInfoByProject'],
  { project_id: string }
>('otherInfo/list', async ({ project_id }, thunkAPI) => {
  try {
    const GraphQL = createClientGraphQL();
    const response = await GraphQL.query({ query: QUERY_ALL_CLIENTS_ADDITIONAL_INFO, variables: { project_id } });
    const { getAllAdditionalInfoByProject } = response.data as ResponseQueryAllClientsAdditionalInfo;
    if (getAllAdditionalInfoByProject) {
      const otherInfo = getAllAdditionalInfoByProject?.filter(
        (data) =>
          data.project_id === project_id && data.tab === 'Altre Informazioni' && ['radio', 'select', 'selectMultiple'].includes(data.type),
      );
      return thunkAPI.fulfillWithValue(otherInfo);
    }
    return thunkAPI.fulfillWithValue([]);
  } catch (error) {
    console.error(error);
    return thunkAPI.rejectWithValue('Failed to load all clientsAdditionalInfo');
  }
});

export const thunkBackOfficeUsers = createAsyncThunk<ResponseQueryBackOfficeUsers['getBackOfficeUsers'], { project_id: string }>(
  'appointees/list',
  async ({ project_id }, thunkAPI) => {
    try {
      const GraphQL = createClientGraphQL();
      const response = await GraphQL.query({ query: QUERY_BACK_OFFICE_USERS, variables: { project_id } });
      const { getBackOfficeUsers } = response.data as ResponseQueryBackOfficeUsers;
      return thunkAPI.fulfillWithValue(getBackOfficeUsers);
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue('Failed to load backOfficeUsers');
    }
  },
);

export const thunkUpdateClientTags = createAsyncThunk<
  ResponseMutationUpdateClientTags['updateClientTags'],
  { project_id: string; id: string; tags: string[] }
>('updateClientTags', async ({ project_id, id, tags }, thunkAPI) => {
  try {
    const GraphQL = createClientGraphQL();
    const response = await GraphQL.mutate({ mutation: MUTATION_UPDATE_CLIENT_TAGS, variables: { project_id, id, tags } });
    const { updateClientTags } = response.data as ResponseMutationUpdateClientTags;
    return thunkAPI.fulfillWithValue(updateClientTags);
  } catch (error) {
    console.error(error);
    return thunkAPI.rejectWithValue('Failed to update client tags');
  }
});
