import React, { memo, useEffect, useState } from 'react';

import styled from '@emotion/styled';
import { Button, Drawer, Spinner, Tag } from '@tecma/ds';
import { useTranslation } from '@tecma/i18n';

import { useModuleAdditionalInfo } from '../../hooks/useModuleAdditionalInfo';
import { useModuleClients } from '../../hooks/useModuleClients';

import './DrawerTagHandler.style.scss';
import { toCamelCase } from '@v2/helpers/toCamelCase';

const FlexContainer = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  flexWrap: 'wrap',
}));
const SpinnerContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: 'calc(100vh - 3.875rem)',
  position: 'absolute',
  top: '3.875rem',
  left: 0,
  backdropFilter: 'blur(0.25rem)',
  background: 'rgba(255, 255, 255, 0.2)',
}));

interface Props {
  open: boolean;
  onClose: () => void;
  projectId: string;
}

export const DrawerTagHandler = memo(({ open, onClose, projectId }: Props) => {
  const { t } = useTranslation();
  const { getClientSelectedTags, drawerTagsUserId, updateClientTags, getUserFullNameById } = useModuleClients(projectId);
  const { tags, getProjectTags, loadingTags } = useModuleAdditionalInfo(projectId);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const isTagSelected = (tag: string) => selectedTags.includes(tag);

  const toggleSelectedTag = (tag: string) => {
    if (!isTagSelected(tag)) {
      setSelectedTags([...selectedTags, tag]);
    } else {
      setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tag));
    }
  };

  const onSave = () => {
    const oldSelectedTags = getClientSelectedTags();
    if (JSON.stringify(oldSelectedTags.sort()) !== JSON.stringify(selectedTags.sort())) {
      updateClientTags(selectedTags);
    }
  };

  useEffect(() => {
    if (drawerTagsUserId) {
      if (!tags) {
        getProjectTags();
      }
      setSelectedTags(getClientSelectedTags());
    }
  }, [tags, drawerTagsUserId]);

  return (
    <Drawer open={open} anchor='right' className='drawer-tag-handler' onClose={onClose}>
      <Drawer.Header onClose={onClose} label={t('drawerTagHandler.title', { user: getUserFullNameById(drawerTagsUserId!) })} />
      <Drawer.Content>
        {loadingTags && (
          <SpinnerContainer>
            <Spinner type='dotted-circle' size='large' />
          </SpinnerContainer>
        )}

        {tags?.map((tagSection) => (
          <div className='section'>
            <h4>{t(`tagSections.${toCamelCase(tagSection.section)}.label`, { defaultValue: tagSection.section })}</h4>
            <FlexContainer className='section-tags'>
              {tagSection.subSections.map((tag) => (
                <Tag
                  className={`${isTagSelected(tag) ? 'selected' : ''}`}
                  label={t(`tagSections.${toCamelCase(tagSection.section)}.tags.${toCamelCase(tag)}`, { defaultValue: tag })}
                  dismissableIcon={isTagSelected(tag) ? 'x' : 'plus'}
                  dismissable
                  // @ts-ignore
                  onTagClick={() => toggleSelectedTag(tag)}
                />
              ))}
            </FlexContainer>
          </div>
        ))}
      </Drawer.Content>

      <Drawer.Footer>
        <Button onClick={onClose} outlined>
          {t('drawerTagHandler.cancel')}
        </Button>
        <Button onClick={onSave}> {t('drawerTagHandler.save')}</Button>
      </Drawer.Footer>
    </Drawer>
  );
});
