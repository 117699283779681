import React from 'react';

import { Accordion, Button } from '@tecma/ds';

import { useModuleAdditionalInfo } from '@v2/@module-clients/hooks/useModuleAdditionalInfo';

import { DrawerFiltersItemSection } from '../../DrawerFiltersItemSection/DrawerFiltersItemSection.component';

import type { AdditionalInfo, AdditionalInfoName, Appointee } from '@v2/@module-clients/declarations/common';

import './DrawerFiltersAccordionContent.style.scss';

interface Props {
  title: string;
  name: AdditionalInfoName | 'appointees';
  onToggle: () => void;
  onToggleLabel: string;
  open?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
  items?: AdditionalInfo[];
  itemsMoreThanLimit: boolean;
  appointees?: Appointee[];
  projectId: string;
  selectedItems?: AdditionalInfo[];
}

export const DrawerFiltersAccordionContent = ({
  items,
  itemsMoreThanLimit,
  appointees,
  title,
  onToggle,
  onToggleLabel,
  open,
  onClick,
  name,
  selectedItems,
  projectId,
}: Props) => {
  const { selectedFilters, onSelectItem, onSelectAppointee } = useModuleAdditionalInfo(projectId);

  return (
    <Accordion.Content
      open={open}
      onClick={onClick}
      title={title}
      className='filter-section'
      iconOnClose='chevron-up'
      iconOnOpen='chevron-down'
      data-testid={`accordion-filter-section-${name}`}
    >
      {name !== 'appointees' && items?.length && (
        <DrawerFiltersItemSection name={name} items={items} selectedItems={selectedItems} onSelectItem={onSelectItem} />
      )}
      {name === 'appointees' && appointees?.length && (
        <DrawerFiltersItemSection
          name={name}
          appointees={appointees}
          selectedAppointees={selectedFilters?.appointees}
          onSelectAppointee={onSelectAppointee}
        />
      )}
      {itemsMoreThanLimit && (
        <Button onClick={onToggle} className='toggle-section-elements-button' size='small' link>
          {onToggleLabel}
        </Button>
      )}
    </Accordion.Content>
  );
};
