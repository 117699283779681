import React, { useEffect, useState } from 'react';

import { Accordion, Button, Spinner } from '@tecma/ds';
import { Trans, useTranslation } from '@tecma/i18n';

import { useModuleAdditionalInfo } from '@v2/@module-clients/hooks/useModuleAdditionalInfo';

import { DrawerFiltersAccordionContent } from './DrawerFiltersAccordionContent/DrawerFiltersAccordionContent.component';
import { DrawerFiltersSelectedItems } from '../DrawerFiltersSelectedItems/DrawerFiltersSelectedItems.component';

import './DrawerFiltersAccordion.style.scss';

interface Props {
  projectId: string;
  handleGoToClientInfoManagement: () => void;
}

export const DrawerFiltersAccordion = ({ projectId, handleGoToClientInfoManagement }: Props) => {
  const { t } = useTranslation();
  const [openPanels, setOpenPanels] = useState<Array<number>>([]);
  const {
    tags,
    actions,
    otherInfo,
    appointees,
    loadingActions,
    loadingOtherInfo,
    loadingTags,
    loadingAppointees,
    getProjectTags,
    getProjectActions,
    getProjectOtherInfo,
    getProjectAppointees,
    itemsToRender,
    itemsExceedLimit,
    selectedFilters,
    activeFilters,
  } = useModuleAdditionalInfo(projectId);
  const [showAllTags, setShowAllTags] = useState<boolean>(false);
  const [showAllActions, setShowAllActions] = useState<boolean>(false);
  const [showAllOtherInfo, setShowAllOtherInfo] = useState<boolean>(false);
  const [showAllAppointees, setShowAllAppointees] = useState<boolean>(false);
  const isLoading = loadingActions || loadingOtherInfo || loadingTags || loadingAppointees;

  const toggleAccordion = (_e: React.MouseEventHandler<HTMLButtonElement>, index: number) => {
    const panels = openPanels.includes(index) ? openPanels.filter((internalId) => internalId !== index) : [...openPanels, index];
    setOpenPanels(panels);
  };
  const handleClick = (index: number) => (e: any) => toggleAccordion(e, index);

  useEffect(() => {
    if (!tags) {
      getProjectTags();
    }
    if (!actions) {
      getProjectActions();
    }
    if (!otherInfo) {
      getProjectOtherInfo();
    }
    if (!appointees) {
      getProjectAppointees();
    }
  }, []);

  return (
    <div className='drawer-filters-accordion-container'>
      {isLoading ? (
        <div className='info-section'>
          <h4 className='section-label'>{t('drawerFilters.filtersSection.loadingFilters')}</h4>
          <Spinner type='dotted-circle' />
        </div>
      ) : (
        <>
          {!!activeFilters && <DrawerFiltersSelectedItems projectId={projectId} />}
          <Accordion className='drawer-filters-accordion' openPanels={openPanels} onClick={toggleAccordion}>
            <DrawerFiltersAccordionContent
              title={t('drawerFilters.filtersSection.tags')}
              name='tags'
              selectedItems={selectedFilters?.tags}
              projectId={projectId}
              items={itemsToRender('tags', showAllTags)}
              itemsMoreThanLimit={itemsExceedLimit('tags')}
              onToggle={() => setShowAllTags((prev) => !prev)}
              onToggleLabel={showAllTags ? t('drawerFilters.filtersSection.seeLess') : t('drawerFilters.filtersSection.seeAll')}
              onClick={handleClick(0)}
            />
            <DrawerFiltersAccordionContent
              title={t('drawerFilters.filtersSection.actions')}
              name='actions'
              selectedItems={selectedFilters?.actions}
              projectId={projectId}
              items={itemsToRender('actions', showAllActions)}
              itemsMoreThanLimit={itemsExceedLimit('actions')}
              onToggle={() => setShowAllActions((prev) => !prev)}
              onToggleLabel={showAllActions ? t('drawerFilters.filtersSection.seeLess') : t('drawerFilters.filtersSection.seeAll')}
              open={openPanels.includes(1)}
              onClick={handleClick(1)}
            />
            <DrawerFiltersAccordionContent
              title={t('drawerFilters.filtersSection.appointees')}
              name='appointees'
              projectId={projectId}
              appointees={appointees}
              itemsMoreThanLimit={itemsExceedLimit('actions')}
              onToggle={() => setShowAllAppointees((prev) => !prev)}
              onToggleLabel={showAllAppointees ? t('drawerFilters.filtersSection.seeLess') : t('drawerFilters.filtersSection.seeAll')}
              open={openPanels.includes(2)}
              onClick={handleClick(2)}
            />
            {otherInfo?.length ? (
              <DrawerFiltersAccordionContent
                title={t('drawerFilters.filtersSection.otherInfo')}
                name='otherInfo'
                selectedItems={selectedFilters?.otherInfo}
                projectId={projectId}
                items={itemsToRender('otherInfo', showAllOtherInfo)}
                itemsMoreThanLimit={itemsExceedLimit('otherInfo')}
                onToggle={() => setShowAllOtherInfo((prev) => !prev)}
                onToggleLabel={showAllOtherInfo ? t('drawerFilters.filtersSection.seeLess') : t('drawerFilters.filtersSection.seeAll')}
                open={openPanels.includes(3)}
                onClick={handleClick(3)}
              />
            ) : (
              <div className='no-other-info-container'>
                <h4>{t('drawerFilters.filtersSection.otherInfo')}</h4>
                <span className='label'>{t('drawerFilters.filtersSection.noOtherInfo.title')}</span>
                <span>
                  <Trans
                    i18nKey='drawerFilters.filtersSection.noOtherInfo.description'
                    components={{
                      buttonLink: <Button onClick={handleGoToClientInfoManagement} link />,
                    }}
                  />
                </span>
              </div>
            )}
          </Accordion>
        </>
      )}
    </div>
  );
};
