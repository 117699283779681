import { memo, useEffect } from 'react';

import { TableMaterial as TecmaTable, Button, Spinner, Select } from '@tecma/ds';
import { useTranslation } from '@tecma/i18n';

import { tableTabs } from '@v2/@module-clients/constants/tableFields';
import { useModuleAdditionalInfo } from '@v2/@module-clients/hooks/useModuleAdditionalInfo';
import { useModuleClients } from '@v2/@module-clients/hooks/useModuleClients';

import { columnsTableClients } from '../../constants/config';
import { DrawerTagHandler } from '../DrawerTagHadler/DrawerTagHandler.component';

import './Table.style.scss';

interface Props {
  project_id: string;
}

export const Table = memo(({ project_id }: Props) => {
  const { t } = useTranslation();
  const {
    clients,
    loading,
    page,
    pageSize,
    totalDocs,
    selectedTab,
    getClients,
    onRefresh,
    onChangePage,
    onChangeRowsPerPage,
    onSortChange,
    onTabChange,
    drawerTagsUserId,
    setDrawerTagsUserId,
  } = useModuleClients(project_id);
  const { tags, getActiveFiltersSearch } = useModuleAdditionalInfo(project_id);
  const showPagination = clients.length > 0;
  const totalPages = Math.ceil(totalDocs / pageSize);
  const columns = columnsTableClients(t, selectedTab, setDrawerTagsUserId, tags);
  const tabOptions = tableTabs(t);
  const selectedTabValue = {
    value: selectedTab,
    label: t(`table.tab.${selectedTab}.label`),
  };

  useEffect(() => {
    const activeFilters = sessionStorage.getItem('activeFilters');
    const filters = activeFilters ? getActiveFiltersSearch(JSON.parse(activeFilters)) : {};
    getClients({ page: 1, perPage: pageSize, searchText: sessionStorage.getItem('searchText') || '' }, filters);
  }, []);

  return selectedTab ? (
    <>
      <TecmaTable
        className='table-clients'
        totalPages={totalPages}
        title={t('table.header.title')}
        isLoading={loading}
        columns={columns}
        data={clients}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onSortChange={onSortChange}
        page={page - 1}
        pageSize={pageSize}
        pageSizeOptions={[5, 10, 25]}
        pageSizeLabel={t('table.rows')}
        emptyDataLabel={t('table.clientNotFound')}
        showPagination={showPagination}
        toolbarActions={
          <>
            <Select options={tabOptions} value={selectedTabValue} onChange={onTabChange} closeMenuOnSelect />
            <Button
              id='table-clients-refresh-button'
              data-testid='table-clients-refresh-button'
              onClick={onRefresh}
              outlined
              iconName={!loading ? 'refresh' : ''}
            >
              {loading && <Spinner size='small' type='dotted-circle' />} {t('table.header.refresh')}
            </Button>
          </>
        }
      />
      <DrawerTagHandler open={!!drawerTagsUserId} onClose={() => setDrawerTagsUserId()} projectId={project_id} />
    </>
  ) : null;
});
