import React, { memo, useEffect, useState } from 'react';

import { Button, Drawer, Input } from '@tecma/ds';
import { useTranslation } from '@tecma/i18n';
import { useHistory } from 'react-router-dom';

import { useModuleAdditionalInfo } from '@v2/@module-clients/hooks/useModuleAdditionalInfo';
import { useModuleClients } from '@v2/@module-clients/hooks/useModuleClients';
import { GestioneUtentiRoute } from 'routes/Routes';
import { useStore } from 'store/storeUtils';

import { DrawerFiltersAccordion } from './DrawerFiltersAccordion/DrawerFiltersAccordion.component';
import { DrawerFiltersSearchResults } from './DrawerFiltersSearchResults/DrawerFiltersSearchResults.component';

import './DrawerFilters.style.scss';

interface Props {
  open: boolean;
  onClose: () => void;
  projectId: string;
}

export const DrawerFilters = memo(({ open, onClose, projectId }: Props) => {
  const { t } = useTranslation();
  const store = useStore();
  const history = useHistory();
  const {
    loadingTags,
    loadingActions,
    loadingAppointees,
    loadingOtherInfo,
    onSaveActiveFilters,
    setSelectedFilters,
    selectedFilters,
    activeFilters,
    getActiveFiltersSearch,
  } = useModuleAdditionalInfo(projectId);
  const { getClients, pageSize } = useModuleClients(projectId);
  const [searchText, setSearchText] = useState('');

  const onSearchChange = (query: string) => {
    setSearchText(query);
  };
  const resetFilters = () => {
    setSelectedFilters();
  };
  const onSave = () => {
    onSaveActiveFilters();
    const activeFilters = sessionStorage.getItem('activeFilters');
    const filters = activeFilters ? getActiveFiltersSearch(JSON.parse(activeFilters)) : {};
    getClients({ page: 1, perPage: pageSize, searchText: sessionStorage.getItem('searchText') || '' }, filters);
    onClose();
  };
  const handleGoToClientInfoManagement = () => {
    onClose();
    const destination = `${GestioneUtentiRoute.to}?tab=other`;
    store.updateCurrentNavTab(GestioneUtentiRoute.order, destination);
    history.push(destination);
  };

  useEffect(() => {
    if (open) {
      setSelectedFilters(activeFilters);
    }
  }, [open]);

  return (
    <Drawer open={open} anchor='right' className='drawer-filters' onClose={onClose}>
      <Drawer.Header onClose={onClose} />
      <Drawer.Content>
        <section className='section-filters'>
          <h4 className='section-title'>{t('drawerFilters.filtersSection.title')}</h4>
          <div className='search-container'>
            <Input
              onChange={(e) => onSearchChange(e.target.value)}
              value={searchText}
              iconName='x'
              onIconClick={() => setSearchText('')}
              leftIconName='search'
              label={t('drawerFilters.filtersSection.searchBar.label')}
              placeholder={t('drawerFilters.filtersSection.searchBar.placeholder')}
              disabled={loadingTags || loadingActions || loadingAppointees || loadingOtherInfo}
            />
            <Button
              onClick={resetFilters}
              outlined
              disabled={!selectedFilters}
              className='clear-filters-button'
              iconName='reply'
              iconSize='small'
            >
              {t('drawerFilters.filtersSection.searchBar.clearButtonLabel')}
            </Button>
          </div>
          <div className='section-content'>
            {searchText?.length > 1 ? (
              <DrawerFiltersSearchResults projectId={projectId} searchText={searchText} />
            ) : (
              <DrawerFiltersAccordion projectId={projectId} handleGoToClientInfoManagement={handleGoToClientInfoManagement} />
            )}
          </div>
        </section>
      </Drawer.Content>

      <Drawer.Footer>
        <Button data-testid='drawer-filter-cancel-button' onClick={onClose} outlined>
          {t('drawerTagHandler.cancel')}
        </Button>
        <Button data-testid='drawer-filter-save-button' onClick={onSave}>
          {t('drawerTagHandler.save')}
        </Button>
      </Drawer.Footer>
    </Drawer>
  );
});
